@font-face {
  font-family: "Baskerville Old Face2";
  src: url("../../fonts/baskerville-old-face.ttf") format("truetype");
}
/* @font-face {
  font-family: "BrownStd-Regular";
  src: url("../../fonts/BrownStd-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "BrownStd-Bold";
  src: url("../../fonts/BrownStdBold.woff") format("woff");
} */
